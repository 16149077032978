import Collapsible from "react-collapsible";
import React/*, { useState }*/ from "react";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import { Box, Tab } from "@mui/material";

export const FormatDropdown = ({
  // hideStatus,
  // setHideStatus,
  formatData,
  optionsFormat,
  ratio,
  format,
  bookHeight,
  bookWidth,
  _onSetFormatTitle,
  getSpecialFormat,
  _onSelectSize,
  _onSetPosition,
  _onAddNewFormat,
  // cover,
  // setCover,
}) => {
  // const [tabState, setTabState] = useState("1");
  // const [tabStateCoverFront, setTabStateCoverFront] = useState("1");
  // const [tabStatePages, setTabStatePages] = useState("1");
  // const [tabStateCoverBack, setTabStateCoverBack] = useState("1");
  // const updateCover = (
  //   side,
  //   formatToUpdate,
  //   updatedElement,
  //   updatedElementIndex
  // ) => {
  //   let newCover = { ...cover };
  //   cover[side][formatToUpdate].elements[updatedElementIndex] = updatedElement;
  //   setCover(newCover);
  // };
  // const frontElem = Object.keys(cover.front);
  const centerElem = Object.keys(formatData);
  // const backElem = Object.keys(cover.back);
  return (
    <>
      {/*<TabContext value={tabState}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TabList
            onChange={(e, newValue) => setTabState(newValue)}
            aria-label="lab API tabs example"
            centered
          >
            <Tab style={{ flex: 1 }} label="Couverture (avant)" value="1" />
            <Tab style={{ flex: 1 }} label="Layouts pages" value="2" />
            <Tab style={{ flex: 1 }} label="Verso (arrière)" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TabContext value={tabStateCoverFront}>
            <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  justifyContent: "center",
                  alignItems: "center",
                }}
            >
              <TabList
                  onChange={(e, newValue) => setTabStateCoverFront(newValue)}
                  aria-label="lab API tabs example"
                  centered
              >
                {frontElem.map((frontElemKey, frontElemInd) => {return <Tab style={{ flex: 1 }} label=frontElemKey value="1" />})}
                <Tab style={{ flex: 1 }} label="Couverture (avant)" value="1" />
                <Tab style={{ flex: 1 }} label="Layouts pages" value="2" />
                <Tab style={{ flex: 1 }} label="Verso (arrière)" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
          {frontElem.map((coverKey) => {
            const coverElem = cover.front[coverKey];
            const { elements } = coverElem;
            return (
              <Collapsible
                key={`${format}CollapseSize${coverKey}front${Math.random().toString(
                  36
                )}`}
                trigger={`Format ${coverKey}`}
              >
                <div
                  style={{
                    padding: "20px 0",
                    border: "4px solid antiquewhite",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "whitesmoke",
                        minHeight: bookHeight,
                        flex: 3,
                        margin: "0 20px",
                      }}
                    >
                      {elements?.map((elem, imgIndPanel) => {
                        return (
                          <>
                            <div
                              key={`${format}CollapseSize${coverKey}${imgIndPanel}front${Math.random().toString(
                                36
                              )}`}
                              style={{
                                display: "flex",
                                backgroundColor: "rgba(0, 0, 0, 0.1)",
                                border: "2px solid steelblue",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 50,
                                  display: "flex",
                                  padding: "0 20px",
                                  alignItems: "center",
                                }}
                              >
                                {imgIndPanel + 1}
                              </div>
                              <div className={"gridZer"}>
                                {elem?.type === "text"
                                  ? null
                                  : optionsFormat?.map((op, opInd) => {
                                      const imageFormat =
                                        getSpecialFormat(elem);
                                      return (
                                        <div
                                          key={`${format}CollapseSize${coverKey}${imgIndPanel}textfront${Math.random().toString(
                                            36
                                          )}`}
                                          onClick={() => {
                                            // _onSelectSize(
                                            //     opInd,
                                            //     imgIndPanel,
                                            //     name,
                                            //     size,
                                            //     format
                                            // );
                                          }}
                                          className={"gridZerElement"}
                                          style={{
                                            backgroundColor: op?.search("H")
                                              ? "blanchedalmond"
                                              : "darkslategray",
                                            color: op?.search("H")
                                              ? "black"
                                              : "white",
                                            border:
                                              imageFormat === op
                                                ? "4px solid red"
                                                : "none",
                                          }}
                                        >
                                          {op}
                                        </div>
                                      );
                                    })}
                              </div>
                              <div>
                                <div
                                  style={{
                                    backgroundColor: "lightgrey",
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "125px",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <h2>x</h2>
                                  <input
                                    onChange={(newVal) => {
                                      updateCover(
                                        "front",
                                        coverKey,
                                        {
                                          ...elem,
                                          start: {
                                            ...elem?.start,
                                            x: parseInt(newVal.target.value),
                                          },
                                        },
                                        imgIndPanel
                                      );
                                      // _onSetPosition(
                                      //     true,
                                      //     newVal.target.value,
                                      //     null,
                                      //     imgIndPanel,
                                      //     name,
                                      //     size,
                                      //     format
                                      // );
                                    }}
                                    value={elem?.start?.x}
                                  />
                                </div>
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "125px",
                                    justifyContent: "space-around",
                                    backgroundColor: "#a87812",
                                  }}
                                >
                                  <h2>y</h2>
                                  <input
                                    onChange={(newVal) => {
                                      // _onSetPosition(
                                      //     false,
                                      //     null,
                                      //     newVal.target.value,
                                      //     imgIndPanel,
                                      //     name,
                                      //     size,
                                      //     format
                                      // );
                                    }}
                                    value={elem?.start?.y}
                                  />
                                </div>
                              </div>
                            </div>
                            <br
                              key={`${format}CollapseSize${coverKey}${imgIndPanel}FirstBrfront${Math.random().toString(
                                36
                              )}`}
                            />
                          </>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        flex: 4,
                        minWidth: `${bookWidth}px`,
                        maxWidth: `${bookWidth + 50}px`,
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          height: `${bookHeight}px`,
                          width: `${bookWidth + 100 / ratio}px`,
                          border: "1px solid black",
                        }}
                      >
                        <div
                          style={{
                            height: `${bookHeight}px`,
                            width: `${100 / ratio}px`,
                            position: "absolute",
                            left: 0,
                            backgroundColor: "#ddfdc1",
                          }}
                        />
                        <div
                          style={{
                            height: `${bookHeight}px`,
                            width: `${bookWidth}px`,
                            top: 0,
                            position: "relative",
                            left: `${100 / ratio}px`,
                          }}
                        >
                          {elements?.map((el, imgInd) => {
                            return (
                              <>
                                <div
                                  key={`${format}CollapseSize${coverKey}${imgInd} DFisplayfront${Math.random().toString(
                                    36
                                  )}`}
                                  style={{
                                    height: `${el?.height / ratio}px`,
                                    maxHeight: `${el?.height / ratio}px`,
                                    width: `${el?.width / ratio}px`,
                                    position: "absolute",
                                    left: `${el?.start?.x / ratio}px`,
                                    top: `${el?.start?.y / ratio}px`,
                                    zIndex: 2,
                                    color: "black",
                                    verticalAlign: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    fontSize:
                                      el?.type === "text" ||
                                      el?.width > el?.height
                                        ? `${
                                            (el?.shadow
                                              ? "6px"
                                              : el?.size / ratio) || "15"
                                          }px`
                                        : "40px",
                                    textAlign: "center",
                                  }}
                                >
                                  {el?.shadow ? "" : `${imgInd + 1}:`}
                                  {el?.content}
                                </div>
                                {el?.type === "text" ? null : (
                                  <img
                                    key={`${format}CollapseSize${coverKey}${imgInd} DFisplayimagefront${Math.random().toString(
                                      36
                                    )}`}
                                    style={{
                                      height: `${el?.height / ratio}px`,
                                      maxHeight: `${el?.height / ratio}px`,
                                      width: `${el?.width / ratio}px`,
                                      position: "absolute",
                                      left: `${el?.start?.x / ratio}px`,
                                      top: `${el?.start?.y / ratio}px`,
                                      objectFit: "cover",
                                    }}
                                    src={
                                      el?.src ||
                                      (el?.width > el?.height
                                        ? "https://media.istockphoto.com/photos/feline-picture-id108226626?k=20&m=108226626&s=612x612&w=0&h=bDaWwxhcygjerdR71qQu8YFSXaDUIi_HUZV2AADg5L8="
                                        : "https://media.istockphoto.com/photos/vertical-portrait-of-an-european-cat-looking-at-camera-picture-id471980156?k=20&m=471980156&s=170667a&w=0&h=cZnc2orkk9bpXc_35yj7Tpf8xwEIGy86BLQvgN-DBBA=")
                                    }
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapsible>
            );
          })}
        </TabPanel>
        <TabPanel value="2"> */}
      {centerElem?.map((size) => {
        const elemForSize = formatData[size];
        return (
          <Collapsible
            key={`${format}CollapseSize${size}`}
            trigger={`${size} images`}
          >
            {elemForSize?.map(({ name, elements }, indLayout) => {
              return (
                <div
                  key={`i${format} temtype2${indLayout}size${size}nm${name}`}
                  style={{ position: "relative" }}
                >
                  <div className={"mediumSteakSubSubCollapsible"}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        position: "absolute",
                        left: 0,
                        flexDirection: "rowe",
                        width: "250px",
                      }}
                    >
                      <div
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          display: "flex",
                          marginRight: "2px",
                          alignItems: "center",
                        }}
                      >
                        Changer titre :{"  "}
                      </div>
                      <form
                        onSubmit={(el) => {
                          el.preventDefault();
                          const e = document.getElementById(
                            `inputNamei${format} temtype2${indLayout}size${size}nm${name}`
                          )?.value;
                          _onSetFormatTitle(name, size, format, e);
                        }}
                      >
                        <input
                          id={`inputNamei${format} temtype2${indLayout}size${size}nm${name}`}
                          style={{
                            width: "60px",
                            fontSize: "15px",
                            height: "30px",
                            marginRight: "5px",
                          }}
                          placeholder={name}
                        />
                        <button>Valider</button>
                      </form>
                    </div>
                    <Collapsible trigger={name}>
                      <div
                        style={{
                          padding: "20px 0",
                          border: "4px solid antiquewhite",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "whitesmoke",
                              minHeight: bookHeight,
                              flex: 3,
                              margin: "0 20px",
                            }}
                          >
                            {elements?.map((elem, imgIndPanel) => {
                              return (
                                <>
                                  <div
                                    key={`itemtype3${imgIndPanel}nm${name}ind${indLayout}sizzz${size}`}
                                    style={{
                                      display: "flex",
                                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                                      border: "2px solid steelblue",
                                      flex: 1,
                                      flexDirection: "row",
                                      justifyContent: "space-around",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: 50,
                                        display: "flex",
                                        padding: "0 20px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {imgIndPanel + 1}
                                    </div>
                                    <div className={"gridZer"}>
                                      {elem?.type === "text"
                                        ? null
                                        : optionsFormat?.map((op, opInd) => {
                                            const imageFormat =
                                              getSpecialFormat(elem);
                                            return (
                                              <div
                                                key={`${format}${indLayout}name${name}elem${opInd}img${imgIndPanel}`}
                                                onClick={() => {
                                                  _onSelectSize(
                                                    opInd,
                                                    imgIndPanel,
                                                    name,
                                                    size,
                                                    format
                                                  );
                                                }}
                                                className={"gridZerElement"}
                                                style={{
                                                  backgroundColor: op?.search(
                                                    "H"
                                                  )
                                                    ? "blanchedalmond"
                                                    : "darkslategray",
                                                  color: op?.search("H")
                                                    ? "black"
                                                    : "white",
                                                  border:
                                                    imageFormat === op
                                                      ? "4px solid red"
                                                      : "none",
                                                }}
                                              >
                                                {op}
                                              </div>
                                            );
                                          })}
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          backgroundColor: "lightgrey",
                                          flexDirection: "row",
                                          display: "flex",
                                          alignItems: "center",
                                          width: "125px",
                                          justifyContent: "space-around",
                                        }}
                                      >
                                        <h2>x</h2>
                                        <input
                                          onChange={(newVal) => {
                                            _onSetPosition(
                                              true,
                                              newVal.target.value,
                                              null,
                                              imgIndPanel,
                                              name,
                                              size,
                                              format
                                            );
                                          }}
                                          value={elem?.start?.x}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          flexDirection: "row",
                                          display: "flex",
                                          alignItems: "center",
                                          width: "125px",
                                          justifyContent: "space-around",
                                          backgroundColor: "#a87812",
                                        }}
                                      >
                                        <h2>y</h2>
                                        <input
                                          onChange={(newVal) => {
                                            _onSetPosition(
                                              false,
                                              null,
                                              newVal.target.value,
                                              imgIndPanel,
                                              name,
                                              size,
                                              format
                                            );
                                          }}
                                          value={elem?.start?.y}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <br
                                    key={`itemtypeBR5${format} 5${imgIndPanel}nm${name}ind${indLayout}sizzz${size}`}
                                  />
                                </>
                              );
                            })}
                          </div>
                          <div
                            style={{
                              flex: 4,
                              minWidth: `${bookWidth}px`,
                              maxWidth: `${bookWidth + 50}px`,
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                height: `${bookHeight}px`,
                                width: `${bookWidth + 100 / ratio}px`,
                                border: "1px solid black",
                              }}
                            >
                              <div
                                style={{
                                  height: `${bookHeight}px`,
                                  width: `${100 / ratio}px`,
                                  position: "absolute",
                                  left: 0,
                                  backgroundColor: "#ddfdc1",
                                }}
                              />
                              <div
                                style={{
                                  height: `${bookHeight}px`,
                                  width: `${bookWidth}px`,
                                  top: 0,
                                  position: "relative",
                                  left: `${100 / ratio}px`,
                                }}
                              >
                                {elements?.map((el, imgInd) => {
                                  return (
                                    <>
                                      <div
                                        key={`itemtype1${imgInd} ${format}Z${indLayout}nameZ${name}`}
                                        style={{
                                          border: "1px solid purple",
                                          height: `${el?.height / ratio}px`,
                                          maxHeight: `${el?.height / ratio}px`,
                                          width: `${el?.width / ratio}px`,
                                          position: "absolute",
                                          left: `${el?.start?.x / ratio}px`,
                                          top: `${el?.start?.y / ratio}px`,
                                          zIndex: 2,
                                          color: "red",
                                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                                          verticalAlign: "center",
                                          justifyContent: "center",
                                          display: "flex",
                                          alignItems: "center",
                                          flexDirection: "column",
                                          fontSize:
                                            el?.type === "text" ||
                                            el?.width > el?.height
                                              ? "30px"
                                              : "40px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {imgInd + 1}
                                        <div
                                          style={{
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            backgroundColor: "lightyellow",
                                            padding: 4,
                                          }}
                                        >
                                          {getSpecialFormat(el)}
                                        </div>
                                      </div>
                                      {el?.type === "text" ? (
                                        <div
                                          key={`itemtype1t3${imgInd} ${format}Z${indLayout}nameZ${name}`}
                                          style={{
                                            fontSize: "15px",
                                            color: "red",
                                            position: "absolute",
                                            left: `${el?.start?.x / ratio}px`,
                                            top: `${el?.start?.y / ratio}px`,
                                          }}
                                        >
                                          {el?.content}
                                        </div>
                                      ) : (
                                        <img
                                          key={`itemtype 5-${imgInd} ${format}Z${indLayout}nameZ${name}`}
                                          style={{
                                            border: "1px solid blue",
                                            height: `${el?.height / ratio}px`,
                                            maxHeight: `${
                                              el?.height / ratio
                                            }px`,
                                            width: `${el?.width / ratio}px`,
                                            position: "absolute",
                                            left: `${el?.start?.x / ratio}px`,
                                            top: `${el?.start?.y / ratio}px`,
                                            objectFit: "cover",
                                          }}
                                          src={
                                            el?.width > el?.height
                                              ? "https://media.istockphoto.com/photos/feline-picture-id108226626?k=20&m=108226626&s=612x612&w=0&h=bDaWwxhcygjerdR71qQu8YFSXaDUIi_HUZV2AADg5L8="
                                              : "https://media.istockphoto.com/photos/vertical-portrait-of-an-european-cat-looking-at-camera-picture-id471980156?k=20&m=471980156&s=170667a&w=0&h=cZnc2orkk9bpXc_35yj7Tpf8xwEIGy86BLQvgN-DBBA="
                                          }
                                          alt=""
                                        />
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </div>
                </div>
              );
            })}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const val = document.getElementById(
                  `newFormat ${format} ${size}`
                )?.value;
                _onAddNewFormat(size, val, format);
              }}
            >
              <input
                style={{ width: "150px", marginRight: "5px" }}
                placeholder={"Nom du nouveau format"}
                id={`newFormat ${format} ${size}`}
              />
              <button
                style={{
                  fontSize: "20px",
                  margin: "20px 0",
                  color: "white",
                  backgroundColor: "darkmagenta",
                  cursor: "pointer",
                }}
              >
                +
              </button>
            </form>
          </Collapsible>
        );
      })}
      {/*</TabPanel>
        <TabPanel value="3">
          {" "}
          {backElem.map((coverKey) => {
            const coverElem = cover.back[coverKey];
            const { elements } = coverElem;
            return (
              <Collapsible
                key={`${format}CollapseSize${coverKey}back${Math.random().toString(
                  36
                )}`}
                trigger={`Format ${coverKey}`}
              >
                <div
                  style={{
                    padding: "20px 0",
                    border: "4px solid antiquewhite",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "whitesmoke",
                        minHeight: bookHeight,
                        flex: 3,
                        margin: "0 20px",
                      }}
                    >
                      {elements?.map((elem, imgIndPanel) => {
                        return (
                          <>
                            <div
                              key={`${format}CollapseSize${coverKey}${imgIndPanel}back${Math.random().toString(
                                36
                              )}`}
                              style={{
                                display: "flex",
                                backgroundColor: "rgba(0, 0, 0, 0.1)",
                                border: "2px solid steelblue",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 50,
                                  display: "flex",
                                  padding: "0 20px",
                                  alignItems: "center",
                                }}
                              >
                                {imgIndPanel + 1}
                              </div>
                              <div className={"gridZer"}>
                                {elem?.type === "text"
                                  ? null
                                  : optionsFormat?.map((op, opInd) => {
                                      const imageFormat =
                                        getSpecialFormat(elem);
                                      return (
                                        <div
                                          key={`${format}CollapseSize${coverKey}${imgIndPanel}textback${Math.random().toString(
                                            36
                                          )}`}
                                          onClick={() => {
                                            // _onSelectSize(
                                            //     opInd,
                                            //     imgIndPanel,
                                            //     name,
                                            //     size,
                                            //     format
                                            // );
                                          }}
                                          className={"gridZerElement"}
                                          style={{
                                            backgroundColor: op?.search("H")
                                              ? "blanchedalmond"
                                              : "darkslategray",
                                            color: op?.search("H")
                                              ? "black"
                                              : "white",
                                            border:
                                              imageFormat === op
                                                ? "4px solid red"
                                                : "none",
                                          }}
                                        >
                                          {op}
                                        </div>
                                      );
                                    })}
                              </div>
                              <div>
                                <div
                                  style={{
                                    backgroundColor: "lightgrey",
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "125px",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <h2>x</h2>
                                  <input
                                    onChange={(newVal) => {
                                      // _onSetPosition(
                                      //     true,
                                      //     newVal.target.value,
                                      //     null,
                                      //     imgIndPanel,
                                      //     name,
                                      //     size,
                                      //     format
                                      // );
                                    }}
                                    value={elem?.start?.x}
                                  />
                                </div>
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "125px",
                                    justifyContent: "space-around",
                                    backgroundColor: "#a87812",
                                  }}
                                >
                                  <h2>y</h2>
                                  <input
                                    onChange={(newVal) => {
                                      // _onSetPosition(
                                      //     false,
                                      //     null,
                                      //     newVal.target.value,
                                      //     imgIndPanel,
                                      //     name,
                                      //     size,
                                      //     format
                                      // );
                                    }}
                                    value={elem?.start?.y}
                                  />
                                </div>
                              </div>
                            </div>
                            <br
                              key={`${format}CollapseSize${coverKey}${imgIndPanel}FirstBrback${Math.random().toString(
                                36
                              )}`}
                            />
                          </>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        flex: 4,
                        minWidth: `${bookWidth}px`,
                        maxWidth: `${bookWidth + 50}px`,
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          height: `${bookHeight}px`,
                          width: `${bookWidth + 100 / ratio}px`,
                          border: "1px solid black",
                        }}
                      >
                        <div
                          style={{
                            height: `${bookHeight}px`,
                            width: `${100 / ratio}px`,
                            position: "absolute",
                            right: 0,
                            backgroundColor: "#ddfdc1",
                          }}
                        />
                        <div
                          style={{
                            height: `${bookHeight}px`,
                            width: `${bookWidth}px`,
                            top: 0,
                            position: "relative",
                            left: `0px`,
                          }}
                        >
                          {elements?.map((el, imgInd) => {
                            return (
                              <>
                                <div
                                  key={`${format}CollapseSize${coverKey}${imgInd} DFisplayback${Math.random().toString(
                                    36
                                  )}`}
                                  style={{
                                    height: `${el?.height / ratio}px`,
                                    maxHeight: `${el?.height / ratio}px`,
                                    width: `${el?.width / ratio}px`,
                                    position: "absolute",
                                    left: `${el?.start?.x / ratio}px`,
                                    top: `${el?.start?.y / ratio}px`,
                                    zIndex: 2,
                                    color: "black",
                                    verticalAlign: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    fontSize:
                                      el?.type === "text" ||
                                      el?.width > el?.height
                                        ? `${
                                            (el?.shadow
                                              ? "6px"
                                              : el?.size / ratio) || "15"
                                          }px`
                                        : "40px",
                                    textAlign: "center",
                                  }}
                                >
                                  {el?.shadow ? "" : `${imgInd + 1}:`}
                                  {el?.content}
                                </div>
                                {el?.type === "text" ? null : (
                                  <img
                                    key={`${format}CollapseSize${coverKey}${imgInd} DFisplayimageback${Math.random().toString(
                                      36
                                    )}`}
                                    style={{
                                      height: `${el?.height / ratio}px`,
                                      maxHeight: `${el?.height / ratio}px`,
                                      width: `${el?.width / ratio}px`,
                                      position: "absolute",
                                      left: `${el?.start?.x / ratio}px`,
                                      top: `${el?.start?.y / ratio}px`,
                                      objectFit: "cover",
                                    }}
                                    src={
                                      el?.src ||
                                      (el?.width > el?.height
                                        ? "https://media.istockphoto.com/photos/feline-picture-id108226626?k=20&m=108226626&s=612x612&w=0&h=bDaWwxhcygjerdR71qQu8YFSXaDUIi_HUZV2AADg5L8="
                                        : "https://media.istockphoto.com/photos/vertical-portrait-of-an-european-cat-looking-at-camera-picture-id471980156?k=20&m=471980156&s=170667a&w=0&h=cZnc2orkk9bpXc_35yj7Tpf8xwEIGy86BLQvgN-DBBA=")
                                    }
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapsible>
            );
          })}
        </TabPanel>
      </TabContext>
      <button
        style={{ width: "100px", height: "50px", display: "none" }}
        onClick={() => {
          setHideStatus(!hideStatus);
        }}
      >
        {hideStatus
          ? "Cliquer pour montrer 21x21"
          : "Cliquer pour cacher 21x21 (performances)"}
      </button>*/}
    </>
  );
};
