import "./App.css";
import MainPage from "./views/MainPage";
import Login from "./views/Login";
// import { useState } from "react";
import { Route, Routes } from "react-router";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/editor" element={<MainPage />} />
      </Routes>
    </div>
  );
}

export default App;
