export const layoutImagesSizes = {
    "15x21": [
        {
            width: 400,
            height: 500,
            type: "vertical",
        },
        {
            width: 500,
            height: 650,
            type: "vertical",
        },
        {
            width: 800,
            height: 1050,
            type: "vertical",
        },
        {
            width: 900,
            height: 1200,
            type: "vertical",
        },
        {
            width: 500,
            height: 350,
            type: "horizontal",
        },
        {
            width: 500,
            height: 400,
            type: "horizontal",
        },
        {
            width: 650,
            height: 500,
            type: "horizontal",
        },
        {
            width: 800,
            height: 600,
            type: "horizontal",
        },
        {
            width: 1300,
            height: 1000,
            type: "horizontal",
        },
        {
            width: 1600,
            height: 1200,
            type: "horizontal",
        },
    ],
    "21x21": [
        {
            width: 350,
            height: 500,
            type: "vertical",
        },
        {
            width: 400,
            height: 500,
            type: "vertical",
        },
        {
            width: 500,
            height: 650,
            type: "vertical",
        },
        {
            width: 600,
            height: 800,
            type: "vertical",
        },
        {
            width: 700,
            height: 900,
            type: "vertical",
        },
        {
            width: 800,
            height: 1050,
            type: "vertical",
        },
        {
            width: 1100,
            height: 1450,
            type: "vertical",
        },
        {
            width: 1350,
            height: 1800,
            type: "vertical",
        },
        {
            width: 500,
            height: 400,
            type: "horizontal",
        },

        {
            width: 650,
            height: 500,
            type: "horizontal",
        },
        {
            width: 800,
            height: 600,
            type: "horizontal",
        },
        {
            width: 850,
            height: 650,
            type: "horizontal",
        },
        {
            width: 1050,
            height: 800,
            type: "horizontal",
        },
        {
            width: 1100,
            height: 850,
            type: "horizontal",
        },
        {
            width: 1200,
            height: 900,
            type: "horizontal",
        },
        {
            width: 1700,
            height: 1300,
            type: "horizontal",
        },
    ],
    "21x31": [
        {
            width: 400,
            height: 500,
            type: "vertical",
        },
        {
            width: 500,
            height: 650,
            type: "vertical",
        },
        {
            width: 600,
            height: 800,
            type: "vertical",
        },
        {
            width: 700,
            height: 900,
            type: "vertical",
        },
        {
            width: 800,
            height: 1050,
            type: "vertical",
        },

        {
            width: 900,
            height: 1200,
            type: "vertical",
        },
        {
            width: 1000,
            height: 1300,
            type: "vertical",
        },
        {
            width: 1300,
            height: 1700,
            type: "vertical",
        },
        {
            width: 500,
            height: 400,
            type: "horizontal",
        },

        {
            width: 650,
            height: 500,
            type: "horizontal",
        },
        {
            width: 800,
            height: 600,
            type: "horizontal",
        },
        {
            width: 900,
            height: 700,
            type: "horizontal",
        },
        {
            width: 1050,
            height: 800,
            type: "horizontal",
        },
        {
            width: 1700,
            height: 1300,
            type: "horizontal",
        },
        {
            width: 2000,
            height: 1500,
            type: "horizontal",
        },
        {
            width: 2250,
            height: 1700,
            type: "horizontal",
        },
    ],
};
