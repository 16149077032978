import "../App.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import {
//   mediumFormatDatabase,
//   smallFormatDatabase,
//   largeFormatDatabase,
// } from "../data/fromDatabase";
// import { layoutImagesSizes } from "../data/fromDocuments";

const Login = () => {
  const navigate = useNavigate();
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const login = async () => {
    await fetch("https://iwi.cantem.fr/user/login-admin", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: mail,
        password,
      }),
    }).then(async (response) => {
      return response.json();
    }).then((res) => {
      if (res?.success) {
        const { layouts, coverSmall, coverMedium, coverLarge } = res;
        navigate("/editor", {
          state: {
            small: layouts['PhotoBookFormat_small'],
            medium: layouts['PhotoBookFormat_medium'],
            large: layouts['PhotoBookFormat_large'],
            layouts: layouts,
          }
        });
      }
    });
  };
  return (
    <div style={{ flex: 1, alignItems: "center", justifyContent: "center", margin: 'auto', }}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await login();
        }}
      >
        <input style={{
          width: '250px',
          height: '35px',
          marginLeft: '12px',
        }}
               placeholder={"Addresse mail"}
               value={mail} onInput={(e) => setMail(e.target?.value)} />
        <input
          value={password}
          style={{
            width: '200px',
            height: '35px',
            marginLeft: '12px',
          }}
          onInput={(e) => setPassword(e.target?.value)}
          placeholder={"Mot de passe"}
          type={"password"}
        />
        <button>
          <p>Connexion</p>
        </button>
      </form>
    </div>
  );
};

export default Login;
