// import logo from "../logo.svg";
import "../App.css";
// import Collapsible from "react-collapsible";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormatDropdown } from "../components/FormatDropdown";
// import { coverLarge, coverMedium, coverSmall } from "../data/fromDatabaseCover";
import { Box, Tab, Tabs } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { layoutImagesSizes } from "../data/fromDocuments";

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [small, setSmall] = useState(location?.state?.small);
  const [medium, setMedium] = useState(location?.state?.medium);
  const [large, setLarge] = useState(location?.state?.large);
  // const [stateCoverSmall, setStateCoverSmall] = useState(coverSmall);
  // const [stateCoverMedium, setStateCoverMedium] = useState(coverMedium);
  // const [stateCoverLarge, setStateCoverLarge] = useState(coverLarge);
  const [hideSmall, setHideSmall] = useState(false);
  const [hideMedium, setHideMedium] = useState(false);
  const [hideLarge, setHideLarge] = useState(false);
  const [currentFormat, setCurrentFormat] = useState("1");
  useEffect(() => {
    if (!location?.state?.layouts) {
      return navigate("/");
    }
  }, []);
  if (!location?.state?.layouts) {
    return <div />;
  }
  const smallRatio = 3;
  const mediumRatio = 4;
  const largeRatio = 8;
  const bookHeight = [1500 / smallRatio, 2100 / mediumRatio, 2000 / largeRatio];
  const bookWidth = [2000 / smallRatio, 2000 / mediumRatio, 3000 / largeRatio];
  const getSpecialFormat = (la) => {
    const horiz = la?.width > la?.height;
    return `${horiz ? "H" : "V"}: ${
      !horiz ? la?.width / 10 : la?.height / 10
    } X ${horiz ? la?.width / 10 : la?.height / 10}`;
  };
  const optionsSmall = layoutImagesSizes["15x21"]?.map(getSpecialFormat);
  const optionsMedium =
    layoutImagesSizes["21x21"]?.map(getSpecialFormat);
  const optionsLarge = layoutImagesSizes["21x31"]?.map(getSpecialFormat);

  const _onAddNewFormat = (
    selectedNumberOfImages,
    newFormatName,
    photoBookType
  ) => {
    let originalFormat;
    let setNewFormat;
    if (photoBookType === "small") {
      originalFormat = small;
      setNewFormat = setSmall;
    } else if (photoBookType === "medium") {
      originalFormat = medium;
      setNewFormat = setMedium;
    } else if (photoBookType === "large") {
      originalFormat = large;
      setNewFormat = setLarge;
    }
    const newFormat = {
      ...originalFormat,
      [`${selectedNumberOfImages}`]: [
        ...originalFormat[selectedNumberOfImages],
        { ...originalFormat[selectedNumberOfImages][0], name: newFormatName },
      ],
    };
    setNewFormat(newFormat);
  };
  const formatFormatter = (originalFormat) => {
    if (originalFormat === "small") {
      return "15x21";
    } else if (originalFormat === "medium") {
      return "21x21";
    } else if (originalFormat === "large") {
      return "21x31";
    }
  };
  const _onSelectSize = (
    selectedOptionInd,
    selectedElementInd,
    selectedFormatName,
    selectedNumberOfImages,
    photoBookType
  ) => {
    let originalFormat;
    let setNewFormat;
    if (photoBookType === "small") {
      originalFormat = small;
      setNewFormat = setSmall;
    } else if (photoBookType === "medium") {
      originalFormat = medium;
      setNewFormat = setMedium;
    } else if (photoBookType === "large") {
      originalFormat = large;
      setNewFormat = setLarge;
    }
    const newFormat = {
      ...originalFormat,
      [`${selectedNumberOfImages}`]: originalFormat[
        selectedNumberOfImages
      ]?.map((format) => {
        if (format?.name === selectedFormatName) {
          return {
            ...format,
            elements: format?.elements?.map((el, indEl) => {
              if (indEl === selectedElementInd)
                return {
                  ...el,
                  width:
                    layoutImagesSizes[formatFormatter(photoBookType)][
                      selectedOptionInd
                    ]?.width,
                  height:
                    layoutImagesSizes[formatFormatter(photoBookType)][
                      selectedOptionInd
                    ]?.height,
                };
              else return el;
            }),
          };
        } else return format;
      }),
    };
    setNewFormat(newFormat);
  };
  const _onSetPosition = (
    horizontal,
    x,
    y,
    selectedElementInd,
    selectedFormatName,
    selectedNumberOfImages,
    photoBookType
  ) => {
    let originalFormat;
    let setNewFormat;
    if (photoBookType === "small") {
      originalFormat = small;
      setNewFormat = setSmall;
    } else if (photoBookType === "medium") {
      originalFormat = medium;
      setNewFormat = setMedium;
    } else if (photoBookType === "large") {
      originalFormat = large;
      setNewFormat = setLarge;
    }
    const newFormat = {
      ...originalFormat,
      [`${selectedNumberOfImages}`]: originalFormat[
        selectedNumberOfImages
      ]?.map((format) => {
        if (format?.name === selectedFormatName) {
          return {
            ...format,
            elements: format?.elements?.map((el, indEl) => {
              if (indEl === selectedElementInd) {
                return {
                  ...el,
                  start: horizontal
                    ? {
                        ...el?.start,
                        x,
                      }
                    : {
                        ...el?.start,
                        y,
                      },
                };
              } else return el;
            }),
          };
        } else return format;
      }),
    };
    setNewFormat(newFormat);
  };

  const _onSetFormatTitle = (
    selectedFormatName,
    selectedNumberOfImages,
    photoBookType,
    newName
  ) => {
    let originalFormat;
    let setNewFormat;
    if (photoBookType === "small") {
      originalFormat = small;
      setNewFormat = setSmall;
    } else if (photoBookType === "medium") {
      originalFormat = medium;
      setNewFormat = setMedium;
    } else if (photoBookType === "large") {
      originalFormat = large;
      setNewFormat = setLarge;
    }
    const newFormat = {
      ...originalFormat,
      [`${selectedNumberOfImages}`]: originalFormat[
        selectedNumberOfImages
      ]?.map((format) => {
        if (format?.name === selectedFormatName) {
          return {
            ...format,
            name: newName,
          };
        } else return format;
      }),
    };
    setNewFormat(newFormat);
  };

  return (
    <div className="App">
      <div className={"mediumSteak"}>
        <TabContext value={currentFormat}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", justifyContent: 'center', alignItems: 'center' }}>
            <TabList
              onChange={(e, newValue) => setCurrentFormat(newValue)}
              aria-label="lab API tabs example"
              centered
            >
              <Tab style={{flex: 1}} label="15 x 21" value="1" />
              <Tab style={{flex: 1}} label="21 x 21" value="2" />
              <Tab style={{flex: 1}} label="20 x 31" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <FormatDropdown
              hideStatus={hideSmall}
              setHideStatus={setHideSmall}
              formatData={small}
              optionsFormat={optionsSmall}
              ratio={smallRatio}
              format={"small"}
              bookHeight={bookHeight[0]}
              bookWidth={bookWidth[0]}
              _onSetFormatTitle={_onSetFormatTitle}
              getSpecialFormat={getSpecialFormat}
              _onSelectSize={_onSelectSize}
              _onSetPosition={_onSetPosition}
              _onAddNewFormat={_onAddNewFormat}
            />
          </TabPanel>
          <TabPanel value="2">
            {" "}
            <FormatDropdown
              hideStatus={hideMedium}
              setHideStatus={setHideMedium}
              formatData={medium}
              optionsFormat={optionsMedium}
              ratio={mediumRatio}
              format={"medium"}
              bookHeight={bookHeight[1]}
              bookWidth={bookWidth[1]}
              _onSetFormatTitle={_onSetFormatTitle}
              getSpecialFormat={getSpecialFormat}
              _onSelectSize={_onSelectSize}
              _onSetPosition={_onSetPosition}
              _onAddNewFormat={_onAddNewFormat}
            />
          </TabPanel>
          <TabPanel value="3">
            <FormatDropdown
              hideStatus={hideLarge}
              setHideStatus={setHideLarge}
              formatData={large}
              optionsFormat={optionsLarge}
              ratio={largeRatio}
              format={"large"}
              bookHeight={bookHeight[2]}
              bookWidth={bookWidth[2]}
              _onSetFormatTitle={_onSetFormatTitle}
              getSpecialFormat={getSpecialFormat}
              _onSelectSize={_onSelectSize}
              _onSetPosition={_onSetPosition}
              _onAddNewFormat={_onAddNewFormat}
            />
          </TabPanel>
        </TabContext>
      </div>
      <CopyToClipboard
        text={JSON.stringify(small)}
        onCopy={() => {
          alert("SMALL 15x21 Copié sur le presse-papier!");
        }}
      >
        <button className={"exportSmallText"}>
          Exporter 15x21
          <span className={"exportSmallRedText"}>{" S"}</span>
        </button>
      </CopyToClipboard>
      <CopyToClipboard
        text={JSON.stringify(medium)}
        onCopy={() => {
          alert("MEDIUM 21x21 Copié sur le presse-papier!");
        }}
      >
        <button className={"exportMediumText"}>
          Exporter 21x21
          <span className={"exportMediumRedText"}>{" M"}</span>
        </button>
      </CopyToClipboard>
      <CopyToClipboard
        text={JSON.stringify(large)}
        onCopy={() => {
          alert("LARGE 21x31 Copié sur le presse-papier!");
        }}
      >
        <button className={"exportLargeText"}>
          Exporter 21x31 <span className={"exportLargeRedText"}>{" L"}</span>
        </button>
      </CopyToClipboard>
      <button className="linkToBackButton">Sauvegarder</button>
    </div>
  );
};

export default MainPage;
